<template lang="html">
<v-container fluid>
  <v-card icon="mdi-account" >
    <div v-if="tampilanHasil" max-height="100vh">
      <hasil-survey :hasil="dataPegawai" :keterangan="keterangan"></hasil-survey>
    </div>
    <div v-else max-height="100vh">
      <v-row id="pegawai" style="position: sticky;top: 1px;z-index: 9999;">
        <v-col class="fixed" style="background-color: white;margin: 0px 0px 12px 12px;">
          <div class="ma-3 d-flex justify-center align-center flex-column">
            <v-avatar tile size="164"> <v-img :src="dataPegawai.data_pegawai.foto"></v-img></v-avatar>
            <h3 class="mt-1 orange--text text--darken-2 headline">{{dataPegawai.data_pegawai.nama_pegawai}}</h3>
            <div class="mt-1 grey--text">NIP. {{dataPegawai.data_pegawai.nip_pegawai}}</div>
            <div class="mt-1 green--text">{{dataPegawai.data_pegawai.jabatan}}</div>
          </div>
        </v-col>
        <v-col class="fixed" style="background-color: white;margin: 0px 12px 12px 0px;">
          <h2 class="display-2">Keterangan Pengisian</h2>
          <v-simple-table dense>
            <tbody>
              <tr  v-for="(ket,i) in keterangan" :key="i">
                <td>{{ket.isi}}</td>
                <td v-html="ket.ket"></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row class="not-fixed">
        <v-col>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
          <div v-for="(q,i) in groupQuesioner" :key="i" class="my-3">
            <h1 class="primary--text display-3 font-weight-bold" style="text-align:center;text-transform:uppercase">{{q.kelompok}}</h1>
            <v-simple-table>
              <tbody>
                <template v-for="(a, j) in q.q">
                  <tr :key="j"><td style="font-size:1em">{{a.nomor}}. <span v-html="a.narasi"></span></td></tr>
                  <tr :key="j+'b'" class="orange--text text--darken-2"><td>
                    <v-radio-group
                      row
                      v-model="radioAnswer[a.id]"
                      :rules="[v => (!isNaN(parseInt(v)) && v >=0 && v <=5) || 'Pilih salah satu']"
                      required
                    >
                      <v-radio v-for="(ket,i) in keterangan"
                        :label="ket.isi"
                        :value="i"
                        :key="i"
                        @change="storeAnswer(a.id, i, a, a.narasi, q.kelompok)"
                      ></v-radio>
                    </v-radio-group>
                  </td></tr>
                </template>
              </tbody>
            </v-simple-table>
          </div>
        </v-form>
        </v-col>
      </v-row>
      <!-- <v-form ref="formSW">
        <v-row class="ma-3">
          <v-col cols=6>
            <v-textarea v-model="formStrengthWeakness.strength" :validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Kelebihan Diri" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
          </v-col>
          <v-col cols=6>
            <v-textarea v-model="formStrengthWeakness.weakness" :validate-on-blur="true" :rules="[rules.required]" :disabled="false" label="Kekurangan Diri" outlined :auto-grow="autoGrow" :no-resize="noResize"></v-textarea>
          </v-col>
        </v-row>
      </v-form> -->
    </div>
    <v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()">tutup</v-btn>
      <confirm-btn v-if="!dataPegawai.hasil_survey" title="Konfirmasi" content="Yakin dengan jawaban anda?" name="Submit" :btnProps="btnProps" @on-agree='onAgree' />
    </v-card-actions>
  </v-card>

  <v-dialog v-model="dialogKeteranganJawaban" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{titleFormKeterangan}}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formKeteranganJawaban">
          <v-row>
            <span><p style="font-size:16px">Kelompok Pertanyaan: &nbsp; </p></span><h3 class="primary--text font-weight-bold" style="text-align:center;text-transform:uppercase" v-html="formKeteranganJawaban.kelompok"></h3>
            <span><p style="font-size:16px" v-html="formKeteranganJawaban.pertanyaan"></p></span>
          </v-row>
          <v-row align="center">
            <v-col cols="12" align="center">
              <v-text hidden v-model="formKeteranganJawaban.id" label="Keterangan" outlined auto-grow></v-text>
              <v-text hidden v-model="formKeteranganJawaban.eselon" label="Keterangan" outlined auto-grow></v-text>
              <v-text hidden v-model="formKeteranganJawaban.nilai" label="Keterangan" outlined auto-grow></v-text>
              <v-text hidden v-model="formKeteranganJawaban.level" label="Keterangan" outlined auto-grow></v-text>
              <v-textarea :disabled="disabledSimpanKeterangan" v-model="formKeteranganJawaban.keterangan" :rules="[v => (v && v.length >= 30) || 'Minimal 30 karakter']" label="Keterangan" outlined auto-grow></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="dialogKeteranganJawaban=false">Tutup</v-btn> -->
        <v-btn id="btn-simpan-keterangan" color="success" :disabled="disabledSimpanKeterangan" @click="simpanKeteranganJawaban()">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import {defaultRules} from '@/utils/lib'
import ConfirmBtn from '@/components/base/ConfirmBtn'
import SurveyReferensiService from '@/services/SurveyReferensiService'
import HasilSurvey from './components/HasilSurvey'
import store from '@/store'
import _ from 'lodash'
import _g from '../../global'
import axios from 'axios'

export default {
  components: {
    ConfirmBtn,
    HasilSurvey
  },

  props: {
    dataPegawai: Object,
  },

  data() {
    return {
      btnProps: {
        name: 'submit'
      },
      valid: true,
      pegawai: {},
      keterangan: [{
          isi: "1",
          ket: "Pernyataan perilaku yang tertera <b>TIDAK PERNAH</b> ditunjukkan oleh kandidat"
        },
        {
          isi: "2",
          ket: "Pernyataan perilaku yang tertera <b>KURANG</b> ditunjukkan oleh kandidat"
        },
        {
          isi: "3",
          ket: "Pernyataan perilaku yang tertera <b>SUDAH MULAI</b> ditunjukkan oleh kandidat, namun <b>BELUM</b> konsisten dilakukan"
        },
        {
          isi: "4",
          ket: "Pernyataan perilaku yang tertera <b>SUDAH</b> ditunjukkan oleh kandidat dan <b>CUKUP</b> konsisten dilakukan"
        },
        {
          isi: "5",
          ket: "Pernyataan perilaku yang tertera <b>SUDAH</b> ditunjukkan oleh kandidat dan <b>SUDAH</b> konsisten dilakukan"
        },
        {
          isi: "6",
          ket: "Pernyataan perilaku yang tertera <b>SUDAH</b> ditunjukkan oleh kandidat dan konsisten dilakukan di berbagai situasi <b>MESKIPUN ADA HAMBATAN/TEKANAN</b>"
        }
      ],
      groupQuesioner: [],
      quesioner: [],
      radioAnswer: [],
      idsQuestion:[],
      savedAnswers:[],
      dialogKeteranganJawaban: false,
      formKeteranganJawaban: {},
      disabledSimpanKeterangan: false,
      rules:{
        ...defaultRules
      },
      jumlah_jawaban: 0,
      formStrengthWeakness: {},
      autoGrow: false,
      noResize: true,
      titleFormKeterangan: ''
    }
  },

  computed:{
    tampilanHasil(){
      console.log(this.dataPegawai.hasil_survey)
      return this.dataPegawai.hasil_survey ? true : false
    },
    // debounceTextStrength: {
    //   get() { return this.text; },
    //   set: _.debounce(function(newValue) {
    //     this.formStrengthWeakness.strength = newValue
    //   }, 100)
    // },
    // debounceTextWeakness: {
    //   get() { return this.text; },
    //   set: _.debounce(function(newValue) {
    //     this.formStrengthWeakness.weakness = newValue
    //   }, 100)
    // },
  },

  watch: {
    'dataPegawai.data_pegawai.nama_pegawai': function(val){
      this.groupQuesioner = []
      console.log('called- A', this.tampilanHasil)
      if (!this.tampilanHasil){
        console.log('called-B', this.tampilanHasil)
        this.$nextTick(() =>{
          this.loadQuesioner()
          this.$refs.form.resetValidation()
        })
      }
      document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
    }
  },

  mounted() {
    if(!this.tampilanHasil){
      this.loadQuesioner()
    }
  },

  destroyed() {
    this.groupQuesioner = []
  },

  methods: {
    onAgree() {
      console.log('agree')
      this.submit()
    },

    submit() {
      // if (this.$refs.formSW.validate()){
      //   var jumlah_jawaban_ = this.jumlah_jawaban
      //   var check_jumlah_jawaban = this.savedAnswers.length
      //   if(check_jumlah_jawaban == 45){
      //     console.log('valid')
      //     SurveyReferensiService.surveyReferensiSubmit(this.dataPegawai.data_pegawai.nip_pegawai,this.radioAnswer.filter(x=>x),this.idsQuestion, this.formStrengthWeakness).then(response =>{
      //       store.commit('snackbar/setSnack',{
      //         message:response.data.message,
      //         color:response.data.response.toLowerCase()})
      //       this.closeEvent()
      //     })
      //   }else{
          if(this.$refs.form.validate()){
            console.log('valid')
            var date = new Date()
            var bulan = date.getMonth()
            var current = this.$store.getters["user/current"]
            var base_url_api = _g.getBaseURLAPIERK(current.year)
            const url_store_jawaban = base_url_api + 'v1/asn-teladan-proses-submit'
            axios.post(url_store_jawaban, {
              api:1,
              nip: this.dataPegawai.data_pegawai.nip_pegawai,
              nip_reviewer: current.username,
              val: this.radioAnswer.filter(x=>x),
              id: this.idsQuestion,
              bulan: (bulan+1),
              // komentar_strength: this.formStrengthWeakness.strength,
              // komentar_weakness: this.formStrengthWeakness.weakness,
              is_sw:false
            }).then(response => {
              store.commit('snackbar/setSnack',{
                message:response.data.message,
                color:'success'
              })
              this.closeEvent()
            }).finally(()=>{
            }).catch(err => {
              var error = err.message
              store.commit('snackbar/setSnack',{message: error, color:'error'})
            })
            // SurveyReferensiService.surveyReferensiSubmit(this.dataPegawai.data_pegawai.nip_pegawai,this.radioAnswer.filter(x=>x),this.idsQuestion, this.formStrengthWeakness).then(response =>{
            //   store.commit('snackbar/setSnack',{
            //     message:response.data.message,
            //     color:response.data.response.toLowerCase()})
            //   this.closeEvent()
            // })
          }else{
            store.commit('snackbar/setSnack',{
                message:'Terjadi kesalahan: Jumlah jawaban masih belum lengkap, silahkan periksa kembali atau reload kembali halaman ulas kandidat ASN teladan.',
                color:'warning'})
          }
        // }
      // }
    },

    cek(){
      return this.dataPegawai.data_pegawai.cek
    },

    loadQuesioner() {
      console.log('load Quesioner -1')
      // this.groupQuesioner = []
      // this.groupQuesioner = this.dataPegawai.kelompok_pertanyaan_survey
      // this.jumlah_jawaban = this.dataPegawai.data_pegawai.cek.length

      // for (let i = 0; i < this.dataPegawai.pertanyaan_survey.length; i++) {
      //   let peg = this.dataPegawai.pertanyaan_survey[i]
      //   let idx = this.groupQuesioner.findIndex(x => x.kelompok === peg.kelompok)
      //   this.idsQuestion.push(peg.id)
      //   try {
      //     this.groupQuesioner[idx]['q'].push(this.dataPegawai.pertanyaan_survey[i])
      //   } catch (error) {
      //     this.groupQuesioner[idx]['q'] = []
      //     this.groupQuesioner[idx]['q'].push(this.dataPegawai.pertanyaan_survey[i])
      //   }
      // }

      this.groupQuesioner = []
      this.groupQuesioner = this.dataPegawai.kelompok_pertanyaan_review
      this.jumlah_jawaban = this.dataPegawai.data_pegawai.cek.length

      for (let i = 0; i < this.dataPegawai.pertanyaan_review.length; i++) {
        let peg = this.dataPegawai.pertanyaan_review[i]
        let idx = this.groupQuesioner.findIndex(x => x.kelompok === peg.kelompok)
        this.idsQuestion.push(peg.id)
        try {
          this.groupQuesioner[idx]['q'].push(this.dataPegawai.pertanyaan_review[i])
        } catch (error) {
          this.groupQuesioner[idx]['q'] = []
          this.groupQuesioner[idx]['q'].push(this.dataPegawai.pertanyaan_review[i])
        }
      }

      // var i = null
      var cek = null
      // assign question id to answer
      for (let i = 0; i < this.idsQuestion.length; i++) {
        this.radioAnswer[this.idsQuestion[i]] = null
      }

      // assign answer to array
      for (let i = 0; i < this.dataPegawai.data_pegawai.cek.length; i++) {
        cek = this.dataPegawai.data_pegawai.cek[i]
        this.radioAnswer[cek.id_m_asn_teladan] = cek.nilai
        this.savedAnswers.push({
          "id" : cek.id_m_asn_teladan,
          "val" : cek.nilai
        });
      }

      // this.formStrengthWeakness.strength = this.dataPegawai.data_pegawai.cek2.komentar_strength //debounceTextStrength
      // this.formStrengthWeakness.weakness = this.dataPegawai.data_pegawai.cek2.komentar_weakness //debounceTextWeakness

      console.log('load Ulas Kandidat -2')
    },


    closeEvent() {
      this.$emit('onClose', false)
      this.$emit('onSubmitted')
    },

    storeAnswer(id,val,a, narasi, kelompok){
      var tes = _.remove(this.savedAnswers, function(v) {
        return v.id != id
      })
      this.savedAnswers = tes
      this.formKeteranganJawaban.pertanyaan = ''
      this.formKeteranganJawaban.kelompok = ''
      if((val+1) >= 5){
        this.titleFormKeterangan = 'Berikan komentar positif anda: '
      }
      if((val+1) <= 2){
        this.titleFormKeterangan = 'Berikan komentar membangun anda: '
      }
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_store_jawaban = base_url_api + 'v1/asn-teladan-proses-review'
      if((val+1) >= 5 || (val+1) <= 2){
        this.formKeteranganJawaban = {}
        // var tipe_pertanyaan_lvl_atas = false
        // if(a.eselon == 0){
          // if(a.level == '3.3' || a.level == '2.3'){
            // tipe_pertanyaan_lvl_atas = true
        this.formKeteranganJawaban.id = id
        this.formKeteranganJawaban.eselon = a.eselon
        this.formKeteranganJawaban.nilai = val
        this.formKeteranganJawaban.level = a.level
        this.dialogKeteranganJawaban = false
        this.formKeteranganJawaban.pertanyaan = a.nomor + '. ' +narasi+ '?'
        this.formKeteranganJawaban.kelompok = kelompok

        axios.post(url_store_jawaban, {
          api:1,
          nip: this.dataPegawai.data_pegawai.nip_pegawai,
          nip_reviewer: current.username,
          id: id,
          val: val,
          keterangan: null
        }).then(response => {
          this.jumlah_jawaban += 1
            this.savedAnswers.push({
              "id" : id,
              "val" : val
            });
            store.commit('snackbar/setSnack',{
              message:response.data.message,
              color:response.data.response.toLowerCase()
            })
        }).finally(()=>{
        }).catch(err => {
          var error = err.message
          this.showDataASNCerdas = false
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
        //   }
        // }
        // if(a.eselon == 3){
        //   if(a.level == '4.3'){
        //     tipe_pertanyaan_lvl_atas = true
        //     this.formKeteranganJawaban.id = id
        //     this.formKeteranganJawaban.eselon = a.eselon
        //     this.formKeteranganJawaban.nilai = val
        //     this.formKeteranganJawaban.level = a.level
        //     this.dialogKeteranganJawaban = true
        //     this.formKeteranganJawaban.pertanyaan = a.nomor + '. ' +narasi+ '?'
        //     this.formKeteranganJawaban.kelompok = kelompok
        //   }
        // }
        // if(a.eselon == 4){
        //   if(a.level == '3.3'){
        //     tipe_pertanyaan_lvl_atas = true
        //     this.formKeteranganJawaban.id = id
        //     this.formKeteranganJawaban.eselon = a.eselon
        //     this.formKeteranganJawaban.nilai = val
        //     this.formKeteranganJawaban.level = a.level
        //     this.dialogKeteranganJawaban = true
        //     this.formKeteranganJawaban.pertanyaan = a.nomor + '. ' +narasi+ '?'
        //     this.formKeteranganJawaban.kelompok = kelompok
        //   }
        // }
        // if(!tipe_pertanyaan_lvl_atas){
          // SurveyReferensiService.surveyReferensiStoreSetiapJawab(this.dataPegawai.data_pegawai.nip_pegawai, id, val, null).then(response=>{
          //   this.jumlah_jawaban += 1
          //   this.savedAnswers.push({
          //     "id" : id,
          //     "val" : val
          //   });
          //   store.commit('snackbar/setSnack',{
          //     message:response.data.message,
          //     color:response.data.response.toLowerCase()})
          // })
        // }
      }else{
        try {
          axios.post(url_store_jawaban, {
            api:1,
            nip: this.dataPegawai.data_pegawai.nip_pegawai,
            nip_reviewer: current.username,
            id: id,
            val: val,
            keterangan: null
          }).then(response => {
            this.jumlah_jawaban += 1
              this.savedAnswers.push({
                "id" : id,
                "val" : val
              });
              store.commit('snackbar/setSnack',{
                message:response.data.message,
                color:response.data.response.toLowerCase()
              })
          }).finally(()=>{
          }).catch(err => {
            var error = err.message
            this.showDataASNCerdas = false
            store.commit('snackbar/setSnack',{message: error, color:'error'})
          })
          // SurveyReferensiService.surveyReferensiStoreSetiapJawab(this.dataPegawai.data_pegawai.nip_pegawai, id, val, null).then(response=>{
          //   this.jumlah_jawaban += 1
          //   this.savedAnswers.push({
          //     "id" : id,
          //     "val" : val
          //   });
          //   store.commit('snackbar/setSnack',{
          //     message:response.data.message,
          //     color:response.data.response.toLowerCase()})
          // })
        } catch (error) {
          return false
        }
      }
    },

    simpanKeteranganJawaban(){
      if (this.$refs.formKeteranganJawaban.validate()){
        this.disabledSimpanKeterangan = true
        let item = document.getElementById("btn-simpan-keterangan");
        item.textContent = 'Silahkan tunggu'
        var id = this.formKeteranganJawaban.id
        var nilai = this.formKeteranganJawaban.nilai
        var keterangan = this.formKeteranganJawaban.keterangan
        SurveyReferensiService.surveyReferensiStoreSetiapJawab(
          this.dataPegawai.data_pegawai.nip_pegawai,
          id,
          nilai,
          keterangan
        ).then(response=>{
          this.jumlah_jawaban += 1
          this.savedAnswers.push({
              "id" : id,
              "val" : nilai
            });
          store.commit('snackbar/setSnack',{message:response.data.message,color:response.data.response.toLowerCase()})
          this.dialogKeteranganJawaban = false
          this.disabledSimpanKeterangan = false
          item.textContent = 'Simpan'
        })
      }
    }
  }

}
</script>

<style lang="css" scoped>
.fixed {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 999;
}
.not-fixed{
  z-index: 0;
}
</style>
