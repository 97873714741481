import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const SurveyReferensiService = {
  surveyReferensiGetData,
  surveyReferensiUlasNIP,
  surveyReferensiStoreSetiapJawab,
  surveyReferensiSubmit,
  // kuesionerHasil,
  // kuesionerSimpanPersetujuanReviewKompetensi
}

function surveyReferensiGetData() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-survey-referensi-saya',
    method: 'post',
    data: {
      nip: current.username
    }
  })
}

function surveyReferensiUlasNIP(nip) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ulas-survey-referensi',
    method: 'post',
    data: {
      "nip": current.username,
      "nip_bawahan": nip
    }
  })
}

function surveyReferensiStoreSetiapJawab(nip, id, val, keterangan) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/survey-referensi-proses-review',
    method: 'post',
    data: {
      nip: nip,
      nip_reviewer: current.username,
      id: id,
      val: val,
      keterangan: keterangan
    }
  })
}

function surveyReferensiSubmit(nip, val_array, id_array, komentar) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url: '/survey-referensi-proses-submit',
    method: 'post',
    data: {
      "nip": nip,
      "nip_reviewer": current.username,
      "val": val_array,
      "id": id_array,
      "bulan": bulan,
      "komentar_strength": komentar.strength,
      "komentar_weakness": komentar.weakness,
    }
  })
}

// function kuesionerHasil(nip, id) {
//   let current = store.state.user.current
//   return request({
//     url: '/kuesioner-kompetensi-lihat-hasil-kuesioner',
//     method: 'post',
//     data: {
//       nip: current.username,
//       nip_bawahan: nip,
//       id: id
//     }
//   })
// }

// function kuesionerSimpanPersetujuanReviewKompetensi(check){
//   let current = store.state.user.current
//   var date = new Date()
//   var bulan = date.getMonth()
//   return request({
//     url: '/review/simpan-persetujuan-kompetensi',
//     method: 'post',
//     data: {
//       nip_reviewer: current.username,
//       bulan: bulan,
//       check: check
//     }
//   })
// }

export default SurveyReferensiService
